import { useTranslation } from 'react-i18next';
import { useMonkAppState } from '@monkvision/common';
import styles from './InspectionCompletePage.module.css';

export function InspectionCompletePage() {
  const { inspectionId } = useMonkAppState();
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{t('inspection-complete.thank-message')}</div>
      <div
        className={styles['link']}
      >{`https://dashboard.tesla.preview.monk.ai/?i=${inspectionId}`}</div>
    </div>
  );
}
